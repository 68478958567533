<template>
  <div class="flex h-screen overflow-hidden bg-gray-100 dark:bg-gray-900 overflow-y-hidden">
    <GlobalNavbar class="z-50"></GlobalNavbar>

    <!-- Map Container on the Left -->
    <div class="relative flex flex-1 z-10 border-b border-gray-300 dark:border-gray-700 overflow-hidden" id="capture">
      <canvas id="drawingCanvas" class="absolute top-0 left-0 z-20" v-show="isDrawing"></canvas>
      <div id="map" class="w-full h-full"></div>
    </div>

    <!-- Map Filters -->
    <div class="absolute top-4 right-4 w-64 z-30">
      <MapFilters :map="map" :context="context" :markers="markers"></MapFilters>
    </div>
<!-- 
    <Moveable
      className="moveable"
      v-bind:target="['.target']"
      v-bind:draggable="true"
      @drag="onDrag"
    /> -->

    <!-- Conflicts Tab -->
  <div
    ref="conflictstab"
    class="target movable fixed top-[28%] md:top-[3.5rem] left-0 w-auto md:w-96 h-[80%] md:h-[94.2%] bg-gray-800 dark:bg-gray-900 border border-gray-600 rounded-lg shadow-lg z-30 overflow-y-auto transform transition-transform duration-300 scrollbar-thin scrollbar-thumb-gray-700 scrollbar-track-gray-900 scrollbar-left"
    :class="{
      'translate-x-0': showConflictsTab,
      'translate-x-[-100%]': !showConflictsTab,
    }"
  >
    <!-- Header -->
    <div class="flex justify-between items-center border-b border-gray-600 mb-4 px-4 py-2 bg-gray-700 dark:bg-gray-800 rounded-t-lg">
      <!-- Close Button -->
      <span
        class="cursor-pointer text-2xl text-gray-400 hover:text-gray-200"
        @click="closeConflictsTab"
      >
        &times;
      </span>
      <!-- Conflict Title -->
      <h2 class="text-xl font-bold text-white truncate mx-auto" :title="selectedConflict">
        {{ selectedConflict }}
      </h2>
      <!-- Follow Button -->
      <div class="flex items-center">
        <FollowConflictButton :selectedConflict="selectedConflict" v-if="conflictSelected" />
      </div>
    </div>

    <!-- Tabs -->
    <TabGroup as="div" :selectedIndex="activeTabIndex" @change="changeTab">
      <TabList class="flex items-center justify-center mb-4 space-x-1 rounded-xl bg-gray-700 dark:bg-gray-800 p-1">
        <Tab
          as="button"
          class="w-1/2 p-2 text-lg font-bold text-white rounded-lg"
          :class="{
            'bg-gray-600': activeTabIndex !== 0,
            'bg-gray-700': activeTabIndex === 0,
          }"
          @click="closeUpdateFeedTab"
        >
          Background
        </Tab>
        <Tab
          as="button"
          class="w-1/2 p-2 text-lg font-bold text-white rounded-lg"
          :class="{
            'bg-gray-600': activeTabIndex !== 1,
            'bg-gray-700': activeTabIndex === 1,
          }"
          @click="openUpdateFeedTab"
        >
          Updates
        </Tab>
      </TabList>

      <TabPanels class="mt-2">
        <!-- Background Panel -->
        <TabPanel v-show="activeTabIndex === 0" class="flex flex-col items-center justify-center h-full">
          <div class="p-4 bg-gray-800 dark:bg-gray-900 rounded-lg shadow-lg">
            <CoalitionContainer :insidesArray="selectedConflictSidesInvolved" />
            <TimeLine
              :events="BackgroundTimeline"
              :conflictName="selectedConflict"
              :map="map"
              :selectedConflictKMLLayer="selectedConflictKMLLayer"
            />
          </div>
        </TabPanel>

        <!-- Updates Panel -->
        <TabPanel v-show="activeTabIndex === 1" class="flex flex-col items-center justify-center h-full">
          <div class="p-4 bg-gray-800 dark:bg-gray-900 rounded-lg shadow-lg">
            <ConflictUpdateLog :selectedConflictUpdates="selectedConflictUpdates" :conflictName="selectedConflict" />
          </div>
        </TabPanel>
      </TabPanels>
    </TabGroup>


  </div>
  </div>
</template>

<script>
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet/dist/images/marker-icon.png";
import "leaflet/dist/images/marker-shadow.png";
// import "@/assets/mobile.css";
import "@/assets/updates.css"

import {
  addGeoJSONClick,
  selectedMobileConflictAdjustments,
  closeConflictsTab,
  closeMobileConflictsTab,
} from "@/services/conflict-tab/conflictTabServices.js";
import { AWSConfig } from "@/services/conflict-tab/AWSConfig.js";
import { checkMobile } from "@/services/computation/checkMobile.js";
import { resetgeoJSONLayers } from "@/services/map/geoJSONsetup.js";
import "@/services/map/SmoothWheelZoomFunction.js";

import { toRaw, computed, watch, ref, onMounted, onBeforeUnmount } from "vue";
import useThemeStore from "@/stores/theme";

import MapFilters from "@/components/map/MapFilters.vue";
import TimeLine from "@/components/conflict-tab/Timeline.vue";
import FollowConflictButton from "@/components/conflict-tab/FollowConflictButton.vue";
import ConflictUpdateLog from "@/components/conflict-tab/ConflictUpdateLog.vue";
import CoalitionContainer from "@/components/conflict-tab/CoalitionsContainer.vue";
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from "@headlessui/vue";
// import Moveable from "vue3-moveable";
import emitter from '@/stores/markerEmitter';

export default {
  name: "HomePage",
  data() {
    return {
      map: null,
      selectedConflict: "Click on a Conflict Zone",
      selectedConflictType: "",
      selectedConflictRegion: "",
      selectedConflictCountries: "",
      selectedConflictSidesInvolved: "",
      selectedConflictBackground: "",
      selectedConflictHistory: "",
      selectedConflictLatestUpdate: "",
      selectedConflictUpdates: [],
      selectedConflictKMLLayer: null,
      selectedConflictHiddenLayer: null,

      BackgroundTimeline: [],
      showConflictsTab: true,
      conflictSelected: false,
      res: window.innerWidth,
      showConflictButtonsTab: !(this.res == 1366),

      isMobile: checkMobile(),
      selectedMobileConflict: null,
      compiledConflicts: [],
      markers: [],
      worldMapView: true,

      isDrawing: false,
      context: null,
      isCutOff: false,
    };
  },
  components: {
    TimeLine,
    FollowConflictButton,
    ConflictUpdateLog,
    CoalitionContainer,
    MapFilters,
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    // Moveable
  },
  metaInfo() {
    return {
      title: "TLDRMap",
      meta: [
        { property: "og:title", content: "TLDRMap" },
        {
          property: "og:description",
          content: "World Updates & Info On The Go",
        },
        {
          property: "og:image",
          content: "https://tldrmap.com/TLDRMapLogo.png",
        },
        { property: "og:url", content: "https://tldrmap.com/" },
        { property: "og:type", content: "website" },
      ],
    };
  },
  created() {
    window.conflicts = this.conflicts;
  },
  setup() {
    const themeStore = useThemeStore();
    const lightLayer = L.tileLayer(
      "https://tile.jawg.io/jawg-terrain/{z}/{x}/{y}{r}.png?access-token={accessToken}",
      {
        attribution:
          '<a href="https://jawg.io" title="Tiles Courtesy of Jawg Maps" target="_blank">&copy; <b>Jawg</b>Maps</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        minZoom: 0,
        maxZoom: 22,
        accessToken: process.env.VUE_APP_JAWG_MAPS_ACCESS_TOKEN,
        zoomAnimation: false,
        fadeAnimation: true,
        markerZoomAnimation: true,
      }
    );

    const darkLayer = L.tileLayer(
      "https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png?api_key={apiKey}",
      {
        minZoom: 0,
        maxZoom: 20,
        attribution:
          '&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        ext: "png",
        apiKey: process.env.VUE_APP_STADIA_MAPS_API_KEY,
      }
    );

    const activeLayer = computed(() => {
      const isDarkMode =
        themeStore.theme === "dark" ||
        (window.matchMedia("(prefers-color-scheme: dark)").matches &&
          themeStore.theme === "auto");
      return isDarkMode ? darkLayer : lightLayer;
    });

    const activeTabIndex = ref(0); // Default to the Background tab

    function changeTab(index) {
      activeTabIndex.value = index;
    }

    function selectTab(index) {
      activeTabIndex.value = index;
      changeTab(index)
    }

    function closeUpdateFeedTab() {
      selectTab(0); // Switch to the Background tab
    }

    function openUpdateFeedTab() {
      selectTab(1); // Switch to the Updates tab
    }

    onMounted(() => {
      emitter.on('openUpdateFeedTab', openUpdateFeedTab);
    });

    onBeforeUnmount(() => {
      emitter.off('openUpdateFeedTab', openUpdateFeedTab);
    });

    return {
      themeStore,
      activeLayer,
      activeTabIndex,
      changeTab,
      openUpdateFeedTab,
      closeUpdateFeedTab,
    };
  },
  async mounted() {
    document.title = "TLDRMap";

    this.map = L.map("map", {
      maxBounds: [
        [-90, -180],
        [90, 180],
      ],
      maxZoom: 20,
      minZoom: 3,
      center: [40, 10],
      zoom: 3,
      scrollWheelZoom: false, // disable original zoom function
      smoothWheelZoom: true,  // enable smooth zoom 
      smoothSensitivity: 3,
    });

    this.activeLayer.addTo(toRaw(this.map));

    watch(() => this.activeLayer,
      (newLayer, oldLayer) => {
        if (this.map.hasLayer(oldLayer)) {
          this.map.removeLayer(oldLayer);
        }
        newLayer.addTo(toRaw(this.map));
        this.map.eachLayer((layer) => {
          if (layer.feature) {
            resetgeoJSONLayers(layer, layer.feature.properties.TypeOfConflict);
          }
        });
      },
    );
    this.AWSConfig();

    document.addEventListener("keydown", this.handleEscapePress);

    this.$nextTick(() => {
      const canvas = document.getElementById("drawingCanvas");
      const container = canvas.parentElement;
      canvas.width = container.clientWidth;
      canvas.height = container.clientHeight;
      const ctx = canvas.getContext("2d");
      ctx.strokeStyle = "#FF0000";
      ctx.lineWidth = 5;
      this.context = ctx;
    });
  },
  methods: {
    AWSConfig() {
      AWSConfig(this);
    },
    // onDrag({ transform }) {
    //   if (this.$refs.conflictstab) {
    //     this.$refs.conflictstab.style.transform = transform;
    //   }
    // },
    /**
     * 
     * @param {*} value 
     */
    setIsDrawing(value) {
      this.isDrawing = value;
    },
    /**
     * 
     * @param {*} newContext 
     */
    updateContext(newContext) {
      this.context = newContext;
    },
    /**
     * Method to Open/Close Conflicts Tab
     */
    toggleConflictsTab() {
      this.showConflictsTab = !this.showConflictsTab;
    },
    /**
     * Method to Open/Close Follow Conflict/Suggestions (for smaller resolutions where Conflict Buttons are on side)
     */
    toggleConflictButtonsTab() {
      this.showConflictButtonsTab = !this.showConflictButtonsTab;
    },
    /**
     * Method For Fixing Scrollbar When Conflicts Tab Timeline Container changes
     * @param {*} timelineContainer 
     */
    resetScrollbar(timelineContainer) {
      timelineContainer.scrollTop = 0;
    },
    /**
     * Method For Adding GeoJSON Click
     * @param {*} geoJSON 
     * @param {*} conflict 
     */
    addGeoJSONClick(geoJSON, conflict) {
      addGeoJSONClick(geoJSON, conflict, this);
    },
    /**
     * 
     * @param {*} newValue 
     */
    selectedMobileConflictAdjustments(newValue) {
      selectedMobileConflictAdjustments(newValue, this);
    },
    /**
     * Method to Close Conflicts Tab, Reset All Conflict Values For Data
     */
    closeConflictsTab() {
      closeConflictsTab(this);
    },
    /**
     * Method to Close Conflicts Tab, Reset All Conflict Values For Mobile, no difference but keeping just in case
     */
    closeMobileConflictsTab() {
      closeMobileConflictsTab(this);
    },
    /**
     * Method for Resetting World Map View on Mobile
     */
    setWorldMapViewFalse() {
      this.worldMapView = true;
    },
    /**
     * Method for Keyboard Shortcut to Close Conflicts Tab
     * @param {*} event 
     */
    handleEscapePress(event) {
      if (event.key === "Escape" || event.keyCode === 27) {
        this.closeConflictsTab();
      }
    },
  },
  watch: {
    /**
     * Watch Function For Selected Conflict on Mobile --> Need to Switch between World Map View, Adjust Map Zoom Settings, Etc
     * @param {*} newValue
     * @async
     * @function
     */
    selectedMobileConflict: async function (newValue) {
      this.selectedMobileConflictAdjustments(newValue);
    },
    /**
     * Adding Markers to Markers Array For Filter Purposes For Mobile View
     * @param {*} newValue 
     * @param {*} oldValue 
     */
    map(newValue, oldValue) {
      if (newValue !== oldValue) {
        newValue.on("layeradd", (e) => {
          if (e.layer instanceof L.Marker && e.layer.options.properties) {
            this.markers.push(e.layer);
          }
        });
      }
    },
  },
  provide() {
    return {
      isDrawing: this.isDrawing,
      context: this.context,
      setIsDrawing: this.setIsDrawing(),
    };
  },
};
</script>


<style scoped>
#map {
  height: 100vh;
}
.scrollbar-left::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

.scrollbar-left::-webkit-scrollbar-thumb {
  background-color: #4a5568; /* Customize the scrollbar thumb color */
  border-radius: 6px;
}

.scrollbar-left::-webkit-scrollbar-track {
  background-color: #1a202c; /* Customize the scrollbar track color */
  border-radius: 6px;
}

.scrollbar-left {
  direction: rtl; /* Reverse the direction */
}

.scrollbar-left * {
  direction: ltr; /* Revert back for content */
}
</style>
